<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Add New Item</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column h-300px scroll-y mx-5 py-7">
          <v-autocomplete
            v-model="formData.skuId"
            dense
            label="SKU"
            item-text="systemCode"
            item-value="skuId"
            clearable
            outlined
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            :error-messages="skuIdErrors"
            @input="$v.formData.skuId.$touch()"
            @blur="$v.formData.skuId.$touch()"
          ></v-autocomplete>
          <v-text-field
            v-model="formData.quantity"
            dense
            label="Quantity"
            type="Number"
            min="1"
            clearable
            outlined
            @change="() => validateMinValue('quantity', 1)"
            @input="$v.formData.quantity.$touch()"
            @blur="$v.formData.quantity.$touch()"
          ></v-text-field>
          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "AddSku",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      skuId: { required },
      quantity: { required },
    },
  },
  props: ["actionFunction", "bundleId"],
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      skuId: null,
      systemCode: null,
      quantity: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      let data = false;
      this.items.forEach((item) => {
        if (item.skuId === this.formData.skuId) {
          data = item;
        }
      });
      this.formData.systemCode = data.systemCode;
      let copy = { ...this.formData };
      this.actionFunction(copy);
      this.toggleModal();
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        skuId: null,
        systemCode: null,
        quantity: null,
      };
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
    async getData(value) {
      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ q: value, sku: this.bundleId }),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    skuIdErrors: function () {
      return this.handleFormValidation("skuId", this);
    },
    quantity: function () {
      return this.handleFormValidation("quantity", this);
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      this.getData(val);
    },
  },
};
</script>
