<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="skus"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >SKUS</span
          >
          <button
            type="submit"
            class="btn btn-light-info"
            @click="() => showAddSkuModal()"
          >
            New Item
          </button>
        </div>
      </template>
    </v-data-table>
    <AddSku
      ref="editSku"
      :bundleId="bundleId"
      :actionFunction="addSkus"
    ></AddSku>
  </div>
</template>

<script>
import AddSku from "@/own/components/fulfillment/bundles/skuAddContainer/AddSku";
export default {
  name: "SkuDatatable",
  props: ["setSku", "skus", "bundleId"],
  components: { AddSku },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "SKU ID",
        value: "skuId",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "System Code",
        value: "systemCode",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "Quantity",
        value: "quantity",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
    ],
  }),
  methods: {
    showAddSkuModal() {
      this.$refs.editSku.toggleModal();
    },
    isRecordExist(item, items) {
      let double = [0, 0];
      items.forEach((element, index) => {
        if (element.skuId === item.skuId) {
          double = [1, index];
        }
      });
      return double;
    },
    addSkus(item) {
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus);
      if (double[0]) {
        new_skus[double[1]] = item;

        this.setSku(new_skus);
      } else {
        new_skus.push(item);
        this.setSku(new_skus);
      }
    },
  },
};
</script>
