<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="skus"
      hide-default-footer
      class="elevation-1"
      mobile-breakpoint="300"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >SKUS</span
          >
          <button
            type="submit"
            class="btn btn-light-info"
            @click="showAddSkuModal"
          >
            New Item
          </button>
        </div> </template
      ><template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :oldIndex="false"
    ></EditSku>
  </div>
</template>

<script>
import EditSku from "@/own/components/fulfillment/bundles/skuEditContainer/EditSku";
import Swal from "sweetalert2";
export default {
  name: "SkuDatatable",
  props: ["setSku", "skus"],
  components: { EditSku },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "SKU ID",
        value: "sku_id",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "System Code",
        value: "system_code",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "Quantity",
        value: "quantity",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
    ],
  }),
  methods: {
    showEditSkuModal(item) {
      let copy = { ...item };
      const index = this.isRecordExist(copy, this.skus);
      this.$refs.editSku.$props.item = copy;
      this.$refs.editSku.$props.oldIndex = index[1];
      this.$refs.editSku.toggleModal();
    },
    showAddSkuModal() {
      this.$refs.editSku.$props.item = false;
      this.$refs.editSku.toggleModal();
    },
    isRecordExist(item, items, oldAddress = false) {
      let double = [0, 0];
      if (oldAddress) {
        items.forEach((element, index) => {
          if (element.sku_id === item.sku_id && index !== oldAddress) {
            double = [1, index];
          }
        });
        return double;
      } else {
        items.forEach((element, index) => {
          if (element.sku_id === item.sku_id) {
            double = [1, index];
          }
        });
        return double;
      }
    },
    editSkus(item, oldAddress = false) {
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus, oldAddress);
      if (oldAddress && double[0] !== 1) {
        new_skus[oldAddress] = item;
        this.setSku(new_skus);
      } else if (oldAddress && double[0] === 1) {
        new_skus[oldAddress] = item;
        this.setSku(new_skus);
        this.removeSkus(new_skus[double[1]]);
      } else {
        if (double[0]) {
          new_skus[double[1]] = item;

          this.setSku(new_skus);
        } else {
          new_skus.push(item);
          this.setSku(new_skus);
        }
      }
    },
    removeSkus(item) {
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus);
      if (double[0]) {
        new_skus.splice(double[1], 1);
        this.setSku(new_skus);
      } else {
        Swal.fire({
          title: "Error",
          text: `Something went wrong!`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
};
</script>
